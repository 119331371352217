
import { createNamespacedHelpers } from 'vuex'
import {
  ACTION_ADD_TO_WISHLIST,
  ACTION_REMOVE_FROM_WISHLIST,
} from '../../../store/actions/wishlist'
const { mapActions: mapActionsWishlist } = createNamespacedHelpers('wishlist')
import { tealiumAnalyticsEvents } from '../../../libs/utils/algoliaIntegration'
import {
  ACTION_RETRIEVE_FAVORITE_LIST,
  ACTION_ADD_REMOVE_FAVORITE,
} from '../../../../scripts/store/actions/plp'
const { mapActions } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-plp-tile-chanel',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    userType: {
      type: String,
      default: 'G',
    },
    redirectGuestFunction: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      useAlgolia: window.algoliaConfig?.isActive || false,
    }
  },
  computed: {
    getAriaLabelDescription() {
      const { brand = '', modelName = '', price = '', colorsNumber = '' } = this.product

      return `${brand} ${modelName}, ${price}, ${colorsNumber}`
    },
    displayedProduct() {
      return this.product
    },
  },
  methods: {
    ...mapActionsWishlist({
      dispatchAddToWishlist: ACTION_ADD_TO_WISHLIST,
      dispatchRemoveFromWishlist: ACTION_REMOVE_FROM_WISHLIST,
    }),
    ...mapActions({
      actionRetrieveFavoriteList: ACTION_RETRIEVE_FAVORITE_LIST,
      actionAddRemoveFavorite: ACTION_ADD_REMOVE_FAVORITE,
    }),
    toggleToWishlist(event) {
      event.preventDefault()
      if (this.displayedProduct?.wishlistRelatedLinks?.isInWishlist) {
        this.dispatchRemoveFromWishlist(this.displayedProduct).then(() => {
          this.actionAddRemoveFavorite({
            catentryId: this.displayedProduct.catentryId,
            isInWishlist: false,
          })
        })
      } else {
        this.dispatchAddToWishlist(this.displayedProduct).then(() => {
          this.useAlgolia
            ? this.actionRetrieveFavoriteList()
            : this.actionAddRemoveFavorite({
                catentryId: this.displayedProduct.catentryId,
                isInWishlist: true,
              })
        })
      }
      //trigger tealium event
      let trackingData = {
        ...this.displayedProduct,
      }
      this.$ma.trackEvent({
        event: 'Event',
        value: trackingData,
      })
    },
    getDataDescription() {
      const brand = this.product.brand.replace(/ /g, '_') || ''
      const modelName = this.product.modelName.replace(/ /g, '_') || ''
      const UPC = this.product.upc || ''

      return brand + '_' + modelName + '_' + UPC
    },
    getModelName() {
      // check comments on https://luxotticaretail.atlassian.net/browse/SGHDP-8893 ... remove CH at ending of the string.
      const regexLastCH = new RegExp(/\sch\d[\S]*$/)
      return this.product.modelName.toLowerCase().replace(regexLastCH, '')
    },
    clickAnalyticsPlpTile(objectID) {
      if (this.useAlgolia && window.tealium_data2track !== undefined) {
        const analyticsParam = {
          objectID: [objectID],
          dataDescription: this.getDataDescription(),
          mainProduct: objectID,
        }
        tealiumAnalyticsEvents(analyticsParam)
      }
    },
  },
}
