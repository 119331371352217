
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { createNamespacedHelpers } from 'vuex'
const { mapActions:mapActionsSearch } = createNamespacedHelpers('search')
const { mapActions:mapActionsPlp } = createNamespacedHelpers('plp')
import {
  ACTION_UPDATE_IDS_FILTERS_CHECKED,
  ACTION_REMOVE_FACET_COUNT,
  ACTION_UPDATE_FACETS,
} from '@store/actions/plp'
import {
  ACTION_UPDATE_IDS_FILTERS_CHECKED as ACTION_UPDATE_IDS_FILTERS_CHECKED_SEARCH,
  ACTION_UPDATE_FACETS as ACTION_UPDATE_FACETS_SEARCH,
  ACTION_REMOVE_FACET_COUNT as ACTION_REMOVE_FACET_COUNT_SEARCH,
} from '@store/actions/search'
import SghToggle from '../toggle/sgh-toggle.vue'
import { prepareFilterTrackingString } from '@libs/wcs/plp'


export default {
  name: 'SghFilterItemToggle',
  components: { SghToggle },
  props: {
    context: {
      type: String,
      default: 'plp',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    selected:{
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      useAlgolia: window.algoliaConfig?.isActive || false,
    }
  },
  computed: {
    facetsToggleList() {
      return this.$store.getters[`${this.context}/facetsToggleList`] || []
    },
    plpLoading() {
      return this.$store.getters[`${this.context}/plpLoading`] || false
    },
    plpLoadingFacets() {
      return this.$store.getters[`${this.context}/plpLoadingFacets`] || false
    },
    isFacetsFirstLoad() {
      return this.$store.getters[`${this.context}/isFacetsFirstLoad`] || false
    },
    idsFiltersCheckedList() {
      return this.$store.getters[`${this.context}/idsFiltersCheckedList`] || []
    },
    extraFacets() {
      return this.$store.getters[`${this.context}/extraFacets`] || []
    },
    hingeFilterIdSA() {
      return this.$store.getters[`${this.context}/hingeFilterIdSA`] || undefined
    },
    facetsCount() {
      return this.$store.getters[`${this.context}/facetsCount`] || undefined
    },
    ui() {
      return this.$store.getters[`${this.context}/ui`] || undefined
    },
    filterOptionsList() {
      return this.$store.getters[`${this.context}/filterOptionsList`] || []
    },
    toggle() {
      return this.label === this[`${this.context}/ui`].filterItemClicked || this.toggleSon
    },
    list() {
      return this.filter.list
    },
    label() {
      return this.filter.label
    },
    others() {
      return this.filter.others
    },
    groupName() {
      return this.filter.groupName
    },
    hideFilters() {
      return this.sizeAdvisorFilterApplied
    },
    sizeAdvisorFilterApplied() {
      return !!(find(this.extraFacets, facet => {
          return facet.includes(this.hingeFilterIdSA)
        }) ||
        find(this.idsFiltersCheckedList, facet => {
          return this.useAlgolia ? facet.includes('Hinge') : facet.includes(this.hingeFilterIdSA)
        }))
    },
    sizeAdvisorExperienceDone() {
      return !isEmpty(sessionStorage.getItem('sizeAdvisorSuggestedHinges'))

    },
    frameAdvisorExperienceDone() {
      return !isEmpty(sessionStorage.getItem('frameAdvisorSuggestedHinges'))
    },
    updateFiltersCheckedAction(){
      return this.context === 'search' ? this.updateIdsFiltersCheckedListSearch : this.updateIdsFiltersCheckedList
    },
    updateFiltersAction(){
      return this.context === 'search' ? this.updateFacetsSearch : this.updateFacets
    },
    filterUpdatePayload(){
      return {
        currentPage: 1,
        override: true,
        updateUi: {
          plpLoadingProducts: true,
        },
      }
    },

    removeFaceCountAction(){
      return this.context === 'search' ? this.removeFacetCountSearch : this.removeFacetCount
    },
  },


  methods: {
    isEmpty,
    ...mapActionsPlp({
      updateIdsFiltersCheckedList: ACTION_UPDATE_IDS_FILTERS_CHECKED,
      removeFacetCount: ACTION_REMOVE_FACET_COUNT,
      updateFacets: ACTION_UPDATE_FACETS,
    }),
    ...mapActionsSearch({
      updateIdsFiltersCheckedListSearch: ACTION_UPDATE_IDS_FILTERS_CHECKED_SEARCH,
      removeFacetCountSearch: ACTION_REMOVE_FACET_COUNT_SEARCH,
      updateFacetsSearch: ACTION_UPDATE_FACETS_SEARCH,
    }),

    sizeAdvSwitchClicked(force) {
      this.suggestedHinges = sessionStorage.getItem('sizeAdvisorSuggestedHinges')
        ? JSON.parse(sessionStorage.getItem('sizeAdvisorSuggestedHinges'))
        : sessionStorage.getItem('frameAdvisorSuggestedHinges')
          ? JSON.parse(sessionStorage.getItem('frameAdvisorSuggestedHinges'))
          : undefined

      if (this.useAlgolia && !isEmpty(this.suggestedHinges))
        this.updateIdsFiltersCheckedList({
          id: `Hinge:{${this.suggestedHinges.minHinge}, ${this.suggestedHinges.maxHinge}}`,
        })
      else if (!isEmpty(this.suggestedHinges) && this.hingeFilterIdSA != undefined) {
        let hingeRangeFacetId = `${this.hingeFilterIdSA}:({${this.suggestedHinges.minHinge} ${this.suggestedHinges.maxHinge}} ${this.suggestedHinges.maxHinge})`
        if (!force || !this.isChecked(hingeRangeFacetId)) this.checked(hingeRangeFacetId)
      } else {
        console.error(
          'No Hinges suggestions from Size / Frame Advisor App found in session storage'
        )
      }
    },
    // rename this method to be more descriptive
    async checked({id,value}){
      await this.updateFiltersCheckedAction({ id, groupName: this.groupName })
      await this.applySort(id)
      this.$emit('filterToggled', { id, value })
      // TODO: update test on click and also the number of filters applied
    },
    isChecked(id) {
      return this.idsFiltersCheckedList?.find(e => {
        return e === id
      })?.length > 0
    },
    disabled({ count }) {
      return parseInt(count) === 0
    },
    hidden(id, count) {
      if (count === 0 && this.facetsCount[id] !== undefined) {
        // if the current filters count is == 0 and
        // in the object with all the filters count at first load
        // the count is=0 then we hide the filter

        return parseInt(this.facetsCount[id]) === 0
      } else if (count > 0 && parseInt(this.facetsCount[id]) === 0) {
        // if the count has become bigger than zero but on
        // the first load count it was 0 than we show the filter again

        this.removeFaceCountAction(id)
        return false
      }
      return false
    },
    async applySort(id) {
      await this.updateFiltersAction(this.filterUpdatePayload)
      prepareFilterTrackingString(
        this.idsFiltersCheckedList,
        this.filterOptionsList,
        this.facets,
        id,
        true
      ).then(trackingData => {
        this.$ma.trackEvent({
          event: 'SearchFilterUpdated',
          value: trackingData,
        })
      })
    },
  },
}
