import { render, staticRenderFns } from "./sgh-toggle.vue?vue&type=template&id=443eef5b&scoped=true"
import script from "./sgh-toggle.vue?vue&type=script&lang=js"
export * from "./sgh-toggle.vue?vue&type=script&lang=js"
import style0 from "./sgh-toggle.vue?vue&type=style&index=0&id=443eef5b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "443eef5b",
  null
  
)

export default component.exports