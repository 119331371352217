

import SghFilterItemToggle from './filter-item-toggle.vue'
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'SghTwentyFourHoursFilter',
  components: { SghFilterItemToggle },
  props: {
    context: {
      type: String,
      default: 'plp',
    },
  },
  computed: {
    facets() {
      return this.$store.getters[`${this.context}/facets`] || {}
    },
    facetsToggleList() {
      return this.$store.getters[`${this.context}/facetsToggleList`] || []
    },
    plpLoading() {
      return this.$store.getters[`${this.context}/plpLoading`] || false
    },
    plpLoadingFacets() {
      return this.$store.getters[`${this.context}/plpLoadingFacets`] || false
    },
    isFacetsFirstLoad() {
      return this.$store.getters[`${this.context}/isFacetsFirstLoad`] || false
    },
    twentyFourHoursFilter() {
      return !isEmpty(this.facetsToggleList) ? this.facetsToggleList?.find((value,_) => {
        return value?.list?.find((listValue,_) => {
          return listValue.algoliaFilterId === 'attributes_translated.24_HOUR_DELIVERY'
        })
      }) : []
      },
    isFilterVisible() {
      return this.twentyFourHoursFilter?.list?.length > 0
    },
    showSkeleton() {
      return this.plpLoadingFacets && !this.isFacetsFirstLoad
    },
  },
  methods:{
    trackFilterToggle({ value }) {
      if(!value) {
        return
      }
      let trackingData = {
        Events_SearchFiltering: '1',
        Search_ResultItemsQnt: this.facets?.numResult?.toString(),
        Search_FacetValues_String: 'Delivery=24h',
      }
      this.$ma.trackEvent({
        event: 'Event',
        value: trackingData,
      })
    },
  },
}
