
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'SghToggle',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validators: value =>
        ['primary', 'secondary'].includes(value),
    },
    size: {
      type: String,
      default: 'big',
      validators: value =>
        ['big', 'small'].includes(value),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    id:{
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data()
  {
    return {
      checkedState: this.checked,
    }
  },
  watch:
    {
      checked(value)
      {
        this.checkedState = value
      },
    },
  created: function() {
    this.$parent.$on('enterBtnClicked', () => {
      this.$emit('changed', this.checkedState)
    })
  },
  methods: {
    isEmpty,
    onChanged(event)
    {
      this.$nextTick(() => {
        this.$emit('changed', event.target.checked)
      })

    },
  },
}
