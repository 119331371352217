
import { createNamespacedHelpers } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import qs from 'qs'
import SghTwentyFourHoursFilter from '../filters/twentyFourHours-filter.vue'
import { getCurrentCountry } from '@libs/utils/currentCountry'

const { mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-header',
  components: { SghTwentyFourHoursFilter },

  data() {
    return {
      isScrolled: false,
      isScrollingUp: false,
      isScrollingDown: false,
      headerMenu: undefined,
      isMultiLine: false,
    }
  },
  computed: {
    ...mapGettersPlp(['facets', 'products','facetsToggleList']),
    heroWidth() {
      return this.isScrolled ? '' : 'heroScrolled'
    },
    filtersExist() {
      return !!this.$slots['filters'] && !!this.$slots['filters'][0]
    },
    isAlgoliaEmptyResponse() {
      return window.algoliaConfig.isActive && this.facets.numResult == 0 && isEmpty(this.products)
    },
    dashButtonsExist() {
      return !!this.$slots['dash-buttons'][0].children
    },
    getSearchTerm() {
      const currentParams = qs.parse(decodeURIComponent(window.location.search), {
        ignoreQueryPrefix: true,
      })

      return currentParams.searchTerm || ''
    },
    isMobileResolution() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    isUk() {
      return getCurrentCountry() === 'uk'
    },
    twentyFourHoursFilterisActive() {
      return !isEmpty(this.facetsToggleList) ? this.facetsToggleList.find((value,_) => {
        return value?.list?.find((listValue,_) => {
          return listValue.algoliaFilterId === 'attributes_translated.24_HOUR_DELIVERY'
        })
      })?.list?.length > 0 && this.isUk : false
    },
  },
  mounted: function () {
    this.animationFrameID = requestAnimationFrame(this.animCheker)
    const bodyPlp = document.querySelector('body').classList.add('bodyPlp')
    this.headerMenu = document.querySelector(`.sgh-main-menu__wrapper.${this.isMobile()}`) // navigation menu + benefitbar
  },
  methods: {
    animCheker: function () {
      const bodySelector = document.querySelector('body')

      if (!bodySelector.classList.contains('overflow-hidden')) {
        const headerMenuHeight = this.headerMenu.clientHeight // navigation menu + benefitbar height

        this.filtersExist ? bodySelector.classList.add('has-filters') : ''
        this.dashButtonsExist ? bodySelector.classList.add('has-dashButtons') : ''
        const headerTop = this.$el.getBoundingClientRect().top
        const globalBanner = document.querySelector('.sgh-header-top')
        const globalBannerHeight = globalBanner.clientHeight
        this.isMultiLine = this.$refs?.headerContent?.clientHeight > 70
        if (headerTop < globalBannerHeight) {
          !bodySelector.classList.contains('filters__fixed')
            ? bodySelector.classList.add('filters__fixed')
            : false
        } else {
          bodySelector.classList.contains('filters__fixed')
            ? bodySelector.classList.remove('filters__fixed')
            : false
        }

        let st

        if (window.navigator.userAgent.indexOf('Edge') > -1) {
          st = document.body.scrollTop
        } else {
          st = document.documentElement.scrollTop
        }

        if (
          st < this.lastScrollTop &&
          this.lastScrollTop > headerMenuHeight &&
          st > headerMenuHeight
        ) {
          this.isScrollingUp = true
          this.isScrollingDown = false
        }

        if (
          st > this.lastScrollTop &&
          this.lastScrollTop > headerMenuHeight &&
          st > headerMenuHeight
        ) {
          this.isScrollingUp = false
          this.isScrollingDown = true
        }

        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight
        const isFullScroll = scrollHeight - (scrollTop + clientHeight) > 200

        if (this.isScrollingUp && isFullScroll) {
          !bodySelector.classList.contains('header-scroll-up')
            ? bodySelector.classList.add('header-scroll-up')
            : ''
          !bodySelector.classList.contains('header-scroll-up-container')
            ? bodySelector.classList.add('header-scroll-up-container')
            : ''
          bodySelector.classList.contains('scroll-down-animate')
            ? bodySelector.classList.remove('scroll-down-animate')
            : ''
        } else if (this.isScrollingDown) {
          this.isScrolled = true
          bodySelector.classList.contains('header-scroll-up-container')
            ? bodySelector.classList.remove('header-scroll-up-container')
            : ''
          if (
            bodySelector.classList.contains('header-scroll-up') &&
            bodySelector.classList.contains('filters__fixed')
          ) {
            bodySelector.classList.remove('header-scroll-up')
          } else if (bodySelector.classList.contains('header-scroll-up')) {
            bodySelector.classList.add('scroll-down-animate')
            setTimeout(() => {
              bodySelector.classList.remove('header-scroll-up')
            }, 300)
          }
        }
        if (window.scrollY < headerMenuHeight) {
          bodySelector.classList.contains('header-scroll-up')
            ? bodySelector.classList.remove('header-scroll-up')
            : ''
          bodySelector.classList.contains('header-scroll-up-container')
            ? bodySelector.classList.remove('header-scroll-up-container')
            : ''
          bodySelector.classList.contains('scroll-down-animate')
            ? bodySelector.classList.remove('scroll-down-animate')
            : ''
          this.isScrollingUp = false
          this.isScrollingDown = false
          this.isScrolled = false
        }

        this.lastScrollTop = st
      }
      this.animationFrameID = requestAnimationFrame(this.animCheker)
    },
    isMobile() {
      return window.innerWidth > 1024 ? 'desktop' : 'mobile'
    },
  },
}
