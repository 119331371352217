
import { createNamespacedHelpers } from 'vuex'
import { ACTION_TOGGLE_PLP_FILTER } from '@store/actions/plp'
import { loadSectionTranslations } from '../../../i18n-setup'
import SghTwentyFourHoursFilter from '@components/filters/twentyFourHours-filter.vue'
import { getCurrentCountry } from '@libs/utils/currentCountry'

const { mapActions, mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')

export default {
  name: 'SghFiltersActionsChanel',
  components: { SghTwentyFourHoursFilter },
  data: function () {
    return {
      isScrolled: false,
      isScrollingUp: false,
      isScrollingDown: false,
    }
  },
  computed: {
    ...mapGettersPlp(['idsFiltersCheckedList', 'ui', 'pagination', 'facetsGroupNames']),
    getLabelResults() {
      let label = ''
      if (this.pagination.totalProducts > 1) {
        label = `${this.pagination.totalProducts} ${this.$t('plp_results')}`
      } else {
        label = `${this.pagination.totalProducts} ${this.$t('plp_result')}`
      }
      return label
    },
    isUk() {
      return getCurrentCountry() === 'uk'
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },
  mounted: function () {
    loadSectionTranslations('plp'),
      (this.animationFrameID = requestAnimationFrame(this.animChecker))
  },
  methods: {
    ...mapActions({
      toggleFilter: ACTION_TOGGLE_PLP_FILTER,
    }),
    animChecker: function () {
      const header = document.querySelector('header')
      const chanelActions = document.querySelector('.sgh-filters-actions__chanel')
      const isSticky = window.pageYOffset > chanelActions.offsetTop

      if (isSticky) {
        header.style.display = 'none'
        chanelActions.style.position = 'sticky'
        chanelActions.style.top = '0'
        chanelActions.style.width = '100%'
        chanelActions.style.backgroundColor = 'white'
      } else {
        header.style.removeProperty('display')
        chanelActions.style.removeProperty('position')
        chanelActions.style.removeProperty('top')
        chanelActions.style.removeProperty('width')
        chanelActions.style.removeProperty('background-color')
      }

      this.animationFrameID = requestAnimationFrame(this.animChecker)
    },
  },
}
