
import { createNamespacedHelpers } from 'vuex'
import { ACTION_TOGGLE_PLP_FILTER, ACTION_UPDATE_FACETS } from '../../../scripts/store/actions/plp'
import { loadSectionTranslations } from '../../i18n-setup'
import { prepareFilterTrackingString } from '../../libs/wcs/plp'
import SghDailyFilter from './twentyFourHours-filter.vue'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import SghTwentyFourHoursFilter from '@components/filters/twentyFourHours-filter.vue'

const { mapActions, mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')

export default {
  name: 'SghFiltersActions',
  components: { SghTwentyFourHoursFilter },
  data: function () {
    return {
      toggleSortPanel: false,
      areLabelsLoaded: false,
    }
  },
  computed: {
    ...mapGettersPlp([
      'facets',
      'facetsSelected',
      'ui',
      'idsFiltersCheckedList',
      'filterOptionsList',
      'sortOptionList',
      'facetsToggleList',
    ]),
    countFiltered() {
      return this.facetsSelected.idsFiltersCheckedList.length > 0
    },
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    isUk() {
      return getCurrentCountry() === 'uk'
    },
  },
  mounted() {
    const filterSkeleton = document.querySelector('.skeleton .filters')
    const bannerSkeleton = document.querySelector('.skeleton .banner')
    const filtersContainer = document.querySelector(
      '.sgh-header__content--filters .sgh-filters-bar'
    )

    const bannerFeedContainer = document.querySelector('.sgh-header__banner-wrapper .product-feed')

    loadSectionTranslations('plp').then(() => {
      this.areLabelsLoaded = true
      //show filters container
      if (filtersContainer) {
        filtersContainer.classList.remove('hidden')
      }

      //show banner feed container
      if (bannerFeedContainer) {
        bannerFeedContainer.classList.remove('hidden')
      }

      //hide filter skeleton
      if (filterSkeleton) {
        filterSkeleton.classList.add('hidden')
      }

      //hide banner icon skeleton
      if (bannerSkeleton) {
        bannerSkeleton.classList.add('hidden')
      }
    })
  },
  methods: {
    ...mapActions({
      toggleFilter: ACTION_TOGGLE_PLP_FILTER,
      updateFacet: ACTION_UPDATE_FACETS,
    }),
    toggleSort() {
      this.toggleSortPanel = !this.toggleSortPanel
    },
    applySort(id) {
      this.updateFacet({
        currentPage: 1,
        orderBy: id,
        override: true,
        updateUi: {
          plpLoadingProducts: true,
        },
      })
      this.toggleSort()
      prepareFilterTrackingString(
        this.idsFiltersCheckedList,
        this.filterOptionsList,
        this.facets,
        id
      ).then(trackingData => {
        this.$ma.trackEvent({
          event: 'SearchFilterUpdated',
          value: trackingData,
        })
      })
    },
  },
}
