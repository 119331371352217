
import { fetchFaqsFromWordLift } from '../../../scripts/libs/wcs/plp.js'
import IntersectionObserverComponent from '../../directives/intersectionObserver.vue'

export default {
  components: {
    IntersectionObserverComponent,
  },
  data() {
    return {
      responseData: null,
      displayedFaqs: [],
      isViewMoreVisible: true,
    }
  },

  methods: {
    async fetchFaqsFromWordLift() {
      try {
        const currentPageUrl = window.location.href
        this.responseData = await fetchFaqsFromWordLift(currentPageUrl)
        this.updateDisplayedFaqs()
      } catch (error) {
        console.error('Error in API call:', error)
      }
    },
    toggleFAQ(index) {
      this.$set(this.displayedFaqs[index], 'isExpanded', !this.displayedFaqs[index].isExpanded)
    },
    toggleViewMore() {
      this.isViewMoreVisible = !this.isViewMoreVisible
      this.updateDisplayedFaqs()
    },
    updateDisplayedFaqs() {
      const numberOfDisplayedFaqs = this.isViewMoreVisible
        ? 6
        : this.responseData[0].mainEntity.length
      this.displayedFaqs = this.responseData[0].mainEntity.slice(0, numberOfDisplayedFaqs)
    },
  },
  computed: {
    shouldShowViewMoreButton() {
      return this.responseData && this.responseData[0].mainEntity.length > 6
    },
  },
}
